import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import {Link} from "react-router-dom";
// import Link from "react-router-dom/modules/Link";
// import { Link } from 'react-router';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

export default function NavBar() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        console.log(event);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    {/*<Menu>*/}

                    {/*</Menu>*/}
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleClick}>
                        <RadioButtonUncheckedIcon/>
                    </IconButton>
                    {/*<Button aria-controls="menu" aria-haspopup="true" onClick={handleClick}>*/}
                    {/*    Open Menu*/}
                    {/*</Button>*/}
                    <Menu
                        id="menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {/*<Link to='/privacy-policy'>*/}
                        <MenuItem onClick={handleClose} component={Link} to="/">Home</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/terms-of-service">Terms of service</MenuItem>
                        {/*</Link>*/}
                        <MenuItem onClick={handleClose} component={Link} to="/privacy-policy">Privacy Policy</MenuItem>
                        {/*<MenuItem onClick={handleClose} component={Link} to="/privacy-policy">Privacy Policy</MenuItem>*/}
                        {/*<MenuItem onClick={handleClose} href="/privacy-policy">Privacy Policy</MenuItem>*/}
                    </Menu>
                    <Typography variant="h6" className={classes.title}>
                        Oura Ring Alexa Skill
                    </Typography>
                    <Button color="inherit" target="blank" href="https://skills-store.amazon.co.uk/deeplink/dp/B083BXBPZF?deviceType=app&share&refSuffix=ss_copy">skill store<ExitToAppIcon/></Button>
                    {/*<Button color="inherit" href='/terms-of-service'>Terms of Service</Button>*/}
                    {/*<Button color="inherit" href='/privacy-policy'>Privacy Policy</Button>*/}
                    {/*<Button color="inherit" href='/'>Home</Button>*/}
                </Toolbar>
            </AppBar>
        </div>
    );
}
