import React from "react";
import {Typography} from "@material-ui/core";
import preval from 'preval.macro'


export default function Home() {
    return (
        <div>
                The following locales have been implemented:
                <ul>
                    <li>en_GB</li>
                    <li>en_US</li>
                </ul>
                <Typography variant="h5">Questions</Typography>
                Ask my ring...
                    <ul>
                        <li>... for my last summary</li>
                        <li>... for my sleep score</li>
                        <li>... for my readiness score</li>
                        <li>... what time I went to bed</li>
                        <li>... what time I got up</li>
                        <li>... how long I was in bed</li>
                        <li>... how long did I sleep</li>
                        <li>... how long was I awake</li>
                        <li>... how long was I in deep sleep</li>
                        <li>... how long I was restless</li>
                        <li>... what my average resting heart rate was</li>
                        <li>... what my lowest resting heart rate was</li>
                        <li>... what my sleep efficiency was</li>
                        <li>... what my breath average was</li>
                        <li>... for my summary for 'date'<br/>example: ask my ring for my summary for first of january 2020</li>
                        <li>... for my heart rate graph<br/>example: <img src={window.location.origin + '/29bc01c4-8405-45ac-b665-4a93c5134970.png'} alt='example' width='300'/></li>
                        <li>... for my heart rate graph of the last 'number' days<br/>example: <img src={window.location.origin + '/b5250e3f-11fe-4aa5-ae0e-c6f1787fbcaa.png'} alt='example' width='300'/></li>
                    </ul>
                    #Todo/coming (probably) soon
                    <ul>
                        <li>Implement activity and spoken feedback regarding values</li>
                        <li>Spanish language (es-ES, es-US)</li>
                        <li>Any other suggestions</li>
                    </ul>

                    Build Date: {preval`module.exports = new Date().toLocaleString();`}.
        </div>
    );
}

