import React from "react";
import NavBar from './components/navbar'
import Home from './pages/home';
import TermsOfService from './pages/terms-of-service';
import PrivacyPolicy from './pages/privacy-policy';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

export default function App() {


    return (
        <Router>


            <NavBar/>
            <div>
                <Switch>
                    <Route path="/terms-of-service">
                        <TermsOfService />
                    </Route>
                    <Route path="/privacy-policy">
                        <PrivacyPolicy />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </div>

        </Router>
    );
}
