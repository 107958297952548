import React from "react";
import {Typography} from "@material-ui/core";

const item_list = [
    {description: 'This agreement governs your use of the Alexa skill on an Alexa enabled device, like Amazon Echo (‘the Skill’).', key: 0},
    {description: 'The right is being preserved to stop the Skill at any time', key: 1},
    {description: 'This is not an official app endorsed by Oura Ring', key: 2},
    {description: 'The graph data - where it is incomplete - is being interpolated and therefore probably not correct', key: 3},

];

export default function TermsOfService() {
    return (
        <div>
            <Typography variant="h4">Terms of Service</Typography>
            <ol>

                    {item_list.map(question => {
                        return (
                            <li key={question.key}>{question.description}</li>
                        );
                    })}

            </ol>
        </div>
    );
}

